<template>
    
    <Section :options="sectionOptions">

        <div class="notes registered">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="50" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg> 
            <h2 v-if="labels.title">{{ $cms.utils.getComputedLabel(labels.title) }}</h2>
            <p v-if="labels.text">{{ $cms.utils.getComputedLabel(labels.text) }}</p>
        </div>

    </Section>

</template>

<style lang="scss">

.notes.registered {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

$green: #7ac142;
  $white: #fff;

  // Misc
  $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

  .checkmark {
    margin-left:auto;
    margin-right:auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: $white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $green;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  .checkmark__circle {
    stroke-dasharray: 250;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $green;
    fill: none;
    animation: stroke .6s $curve forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s $curve .8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 40px $green;
    }
  }

.notes h2 {
  margin-top:20px;
  text-align: center;
  margin-bottom:0px;
}

.notes p {
  text-align: center;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface RegisteredOptions {
  /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface RegisteredLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsContent */
  text?:CmsContent;
}

/** @cmsSlots */
export interface RegisteredSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<RegisteredOptions>,
        labels: {
          type: Object as PropType<RegisteredLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<RegisteredSlots>
    },
    components: {
        Section
    },
    setup(props, context) {
        const app = getApp();

        const labels:RegisteredLabels = {
            title: app.$t('authentication.registered.title'),
            text: app.$t('authentication.registered.text'),
            ...props.labels
        }

        return {
            labels
        }

    }
})
</script>